import { Provider } from '@lazr/enums'
import { Search } from '@/app/model'
import { 
    CanadaPostSpecifics, 
    CanadaPostSpecificsOptional,
} from '@/app/model/ProviderSpecifics'


export const createCanadaPostSpecificObject = (provider: Provider | undefined, order: Partial<Search>, isDraft = false, isMarketplace = false): CanadaPostSpecifics | CanadaPostSpecificsOptional => {
    let canadaPostSpecifics = {
        canadaPostSpecifics: {
            ParcelPakEnvelope: {
                documentsOnlyIndicator: !!order.rfq?.documentsOnlyIndicator,
                billDutiesToParty: order.rfq?.billDutiesToParty?.code ?? null,
                dutyBillToCity: order.rfq?.dutyBillToCity ?? null,
                dutyBillToContactEmail: order.rfq?.dutyBillToContactEmail ?? null,
                dutyBillToCompanyName: order.rfq?.dutyBillToCompanyName ?? null,
                dutyBillToContactName: order.rfq?.dutyBillToContactName ?? null,
                dutyBillToContactPhone: order.rfq?.dutyBillToContactPhone ?? null,
                dutyBillToContactPhoneExt: order.rfq?.dutyBillToContactPhoneExt ?? null,
                dutyBillToCountry: order.rfq?.dutyBillToCountry?.code ?? null,
                dutyBillToName: order.rfq?.dutyBillToName ?? null,
                dutyBillToPostalCode: order.rfq?.dutyBillToPostalCode ?? null,
                dutyBillToState: order.rfq?.dutyBillToState ?? null,
                dutyBillToStreetAddress: order.rfq?.dutyBillToStreetAddress ?? null,
                dutyBillToStreetAddress2: order.rfq?.dutyBillToStreetAddress2 ?? null,
                dutyBillToStreetAddress3: order.rfq?.dutyBillToStreetAddress3 ?? null,
                reasonForExportType: order.rfq?.reasonForExport?.code,
                dutyCurrency: order.rfq?.dutyCurrency?.code ?? null,
            }
        }
    } as unknown as CanadaPostSpecifics | CanadaPostSpecificsOptional

    if (!isDraft) {
        if (!order.rfq?.reasonForExport ||
            !order.rfq?.nonDeliveryHandling?.code ||
            !order.rfq?.dutyCurrency?.code
        ) {
            throw new Error(('Required fields missing.'))
        }

        if (!order.rfq?.documentsOnlyIndicator && order.rfq?.commercialInvoiceDocumentIndicator && !order.rfq?.commercialInvoiceUserProvided) {
            if (!order.rfq?.billDutiesToParty?.code) {
                throw new Error(('Required fields missing.'))
            }
        }

        canadaPostSpecifics = {
            ...canadaPostSpecifics,
            ParcelPakEnvelope: {
                ...canadaPostSpecifics.canadaPostSpecifics.ParcelPakEnvelope,
                reasonForExportType: order.rfq.reasonForExport.code,
                nonDeliveryHandlingType: order.rfq.nonDeliveryHandling.code,
            }
        } as unknown as CanadaPostSpecifics
    } else {
        canadaPostSpecifics = {
            ...canadaPostSpecifics,
            ParcelPakEnvelope: {
                ...canadaPostSpecifics.canadaPostSpecifics.ParcelPakEnvelope,
                reasonForExportType: order.rfq?.reasonForExport?.code,
                nonDeliveryHandlingType: order.rfq?.nonDeliveryHandling?.code,
            }
        } as unknown as CanadaPostSpecificsOptional
    }
        
    return canadaPostSpecifics
}
